/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'

import { palette } from '../styles/theme'
import { device } from '../device'

const showTwitter = false

const SocialWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SocialImage = styled(props => <Image {...props} />)`
  margin-bottom: 36px;
  min-width: 96px;
  border-radius: '100%';
`

const SocialDescription = styled.p`
  margin: 0;
  padding: 0;
  max-width: 500px;
  display: block;
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.25rem;
  line-height: 1.5;
  text-align: center;
  color: ${palette.color6};
  @media ${device.intermediate} {
    font-size: 1.5rem;
    line-height: 2;
  }
`

const SocialLink = styled.a`
  color: ${palette.color6};
  text-decoration-color: ${palette.color4};
  box-shadow: none;
  cursor: pointer;
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/me-rockin-out.jpg/" }) {
        childImageSharp {
          fixed(width: 96, height: 96) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <SocialWrapper>
      <SocialImage
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        imgStyle={{
          borderRadius: '50%',
        }}
      />
      <SocialDescription>
        Written by <strong>{author}</strong> - some music production nerd
        {showTwitter ? (
          <SocialLink href={`https://twitter.com/${social.twitter}`}>
            You should follow him on Twitter
          </SocialLink>
        ) : null}
      </SocialDescription>
    </SocialWrapper>
  )
}

export default Bio
