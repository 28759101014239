import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'

import { WrapRootElement } from './styles'

import { device } from '../device'
import { spacers, palette } from '../styles/theme'
import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { PostShape } from '../types'

const PostArticle = styled.article`
  margin: 0;
  padding: 0;
  max-width: 1204px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  code {
    background-color: ${palette.color6};
    color: ${palette.color1};
    font-size: 1.5rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
`

const PostImage = styled(props => <Image {...props} />)`
  margin: 0;
  margin-bottom: ${spacers.spacer3};
  margin-top: ${props => (props.addMarginTop ? spacers.spacer3 : '0')};
  margin-top: ${spacers.spacer3};
  width: 100%;
  max-height: 270px;
  > picture > img,
  img,
  * {
    margin: 0;
    padding: 0;
    object-fit: ${props =>
      props.containImage ? 'contain !important' : 'cover'};
  }
  @media ${device.intermediate} {
    margin-top: ${props => (props.addMarginTop ? spacers.spacer6 : '0')};
    margin-bottom: ${spacers.spacer6};
    max-height: 268px;
  }
  @media ${device.web} {
    margin-top: ${props => (props.addMarginTop ? spacers.spacer7 : '0')};
    margin-bottom: ${spacers.spacer7};
    max-height: 432px;
  }
`

const PostHeader = styled.header`
  margin: 0;
  margin-bottom: ${spacers.spacer5};
  padding: 0;
`

const PostTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-family: 'Jorge-Luis-Cafe-Bold';
  font-size: 1.875rem;
  line-height: 1.5;
  color: ${palette.color6};
  @media ${device.intermediate} {
    font-size: 2.25rem;
  }
  @media ${device.web} {
  }
`

const PostSmall = styled.small`
  margin: 0;
  margin-bottom: ${spacers.spacer1};
  padding: 0;
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.125rem;
  line-height: 1;
  color: ${palette.color4};
`

const PostFooter = styled.footer`
  margin: 0;
  margin-bottom: ${spacers.spacer5};
  padding: 0;
`

const PostWrapperLink = styled.div`
  margin: 0;
  margin-bottom: ${spacers.spacer5};
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    margin-bottom: ${spacers.spacer3};
  }
  @media ${device.intermediate} {
    flex-direction: row;
    justify-content: space-around;
    > :first-child {
      margin-bottom: 0;
    }
  }
`

const GatsbyLink = styled(props => <Link {...props} />)`
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-shadow: none;
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 1.25rem;
  line-height: 1.5;
  color: ${palette.color5};
  border-bottom: 1px solid ${palette.color4};
  cursor: pointer;
  @media ${device.intermediate} {
    font-size: 1.5rem;
    line-height: 2;
  }
`

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  return (
    <Layout isBlog location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <PostArticle>
        <PostImage
          fluid={post.frontmatter.image.childImageSharp.fluid}
          alt={`Image of ${siteTitle}`}
          addMarginTop={post.frontmatter.addMarginTop}
          containImage={post.frontmatter.containImage}
        />
        <PostHeader>
          <PostTitle>{post.frontmatter.title}</PostTitle>
          <PostSmall>{post.frontmatter.date}</PostSmall>
        </PostHeader>
        <WrapRootElement>
          <div id="innerHtml" dangerouslySetInnerHTML={{ __html: post.html }} />
        </WrapRootElement>
        <PostFooter>
          <Bio />
        </PostFooter>
      </PostArticle>

      <PostWrapperLink>
        {previous && (
          <GatsbyLink to={previous.fields.slug} rel="prev">
            {'< '} {previous.frontmatter.title}
          </GatsbyLink>
        )}
        {next && (
          <GatsbyLink to={next.fields.slug} rel="next">
            {next.frontmatter.title} {' >'}
          </GatsbyLink>
        )}
      </PostWrapperLink>
    </Layout>
  )
}

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PostShape,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    previous: PostShape,
    next: PostShape,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        addMarginTop
        containImage
        image {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
